import React, { Component } from 'react'
import Link from 'next/link';
import * as gtag from '../../lib/gtag';

import { Col, Row } from 'antd';
import { domainName, sortAlphabetically, staticAssetsPrefix, websiteUrl, appStoreUrlLanding, playStoreUrl, windowsStoreUrl, macStoreUrl } from '../../lib/utils';
import { colors, spacing, minScreenSize, maxScreenSize } from '../../lib/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class LandingMain2 extends Component {

    constructor(props) {
        super(props);
        this.state = { isVideoPaused: true, showRating: false };
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ showRating: true });
        }, 300);
        for (const video of document.querySelectorAll('.video-main')) {
            video.controls = false
            video.addEventListener('mouseover', () => { video.controls = 'controls' })
            video.addEventListener('mouseout', () => { video.controls = false })
            video.addEventListener('pause', () => { this.setState({ isVideoPaused: true }) })
            video.addEventListener('play', () => { this.setState({ isVideoPaused: false }) })
        }
    }

    play = () => {
        for (const video of document.querySelectorAll('.video-main')) {
            video.play();
        }
    }

    // showControls = (video) => {
    //     video.controls = 'controls'
    // }
    // hideControls = (video) => {
    //     video.controls = false
    // }

    componentWillUnmount() {
        for (const video of document.querySelectorAll('.video-main')) {
            // video.removeEventListener('mouseover')
            // video.removeEventListener('mouseout')
        }
    }

    render() {
        const { isVideoPaused, showRating } = this.state;
        const webUrl = websiteUrl + '/top-charts';
        const webUrlDownload = webUrl + '?downloadApp=true';
        return (
            <div>
                <div className='img-parent'>
                    <img src={`${staticAssetsPrefix}/static/images/bg-6.png`} />
                </div>
                <section className="new-bg text-center text-light">
                    {/* <div className="hero-bg"></div>
                    <div className="hero-particles-container">
                        <canvas id="hero-particles"></canvas>
                    </div> */}
                    <div className="container-sm">
                        <div className="">
                            {/* <Row style={{}}>
                                <Col xs={24} md={24} xl={{ span: 24, offset: 0 }} style={{ textAlign: 'center' }}> */}
                            <div className='main-title' style={{ zIndex: '10' }}>
                                {/* <h1 className='serif-bold-extra'>Limited Time Offer</h1> */}
                                <Row>
                                    <Col xs={24} md={11} style={{ margin: 'auto' }} className='main-title-first'>
                                        <h1 className='title-align dark' style={{ marginBottom: '4px' }}>
                                            Podcast App
                                            for People
                                            Short on Time
                                        </h1>
                                        <h3 className='title-align serif' style={{ fontSize: '1.25em' }}>
                                            Listen Smarter &
                                            Save Up to
                                            9 Hrs per Week with:
                                        </h3>
                                        <div style={{ textAlign: 'left' }}>
                                            <div style={{ marginBottom: '8px' }}><div className='icon-bg'><FontAwesomeIcon className='' icon={['fas', 'bolt']} /></div><h3 className='serif' style={{ display: 'inline' }}>Podcast Summaries</h3></div>
                                            <div style={{ marginBottom: '8px' }}><div className='icon-bg'><FontAwesomeIcon className='' icon={['fas', 'bolt']} /></div><h3 className='serif' style={{ display: 'inline' }}>AI Chapters</h3></div>
                                            <div style={{ marginBottom: '8px' }}><div className='icon-bg'><FontAwesomeIcon className='' icon={['fas', 'bolt']} /></div><h3 className='serif' style={{ display: 'inline' }}>Episode Trailers</h3></div>
                                            <div style={{ marginBottom: '8px' }}><div className='icon-bg'><FontAwesomeIcon className='' icon={['fas', 'bolt']} /></div><h3 className='serif' style={{ display: 'inline' }}>And Much More&hellip;</h3></div>
                                        </div>
                                        {/* <p className='serif' style={{ color: '#cdceda' }}>Best Podcast player for all your devices.</p> */}
                                        {/* <p className='desktop-apps'><a href={webUrlDownload}>Windows 10</a>&nbsp; &nbsp; &nbsp;<a href={webUrlDownload}>Mac OS</a></p> */}
                                        <div className='title-align btn-width' style={{ marginTop: '24px' }}>
                                            {/* <Link href='/onboard' > */}
                                            <a href={`${websiteUrl}/top-charts`} onMouseDown={() => gtag.basicEvent('web-player-click')}>
                                                <button className='button app-btn-primary' style={{ cursor: 'pointer' }}>
                                                    <h4 className='serif'>Get Started</h4>
                                                </button>
                                            </a>
                                            {/* </Link> */}
                                        </div>
                                        <div className='app-stores' style={{ marginTop: '20px' }}>
                                            <a style={{ marginRight: '1em' }} href={appStoreUrlLanding} target='_blank' rel="noopener noreferrer">
                                                <img style={{ width: '130px' }} src={`${staticAssetsPrefix}/static/images/app_store.svg`} />
                                            </a>
                                            <a href={playStoreUrl} target='_blank' rel="noopener noreferrer">
                                                <img style={{ width: '148px' }} src={`${staticAssetsPrefix}/static/images/google-play-badge.svg`} />
                                            </a>
                                            {/* <a href={windowsStoreUrl} style={{ marginLeft: '12px' }} target='_blank' rel="noopener noreferrer">
                                        <img style={{ width: '160px', objectFit: 'cover', height: '42px', borderRadius: '8px' }} src={`${staticAssetsPrefix}/static/images/windows-badge.svg`} />
                                    </a>
                                    <a href={macStoreUrl} style={{ marginLeft: '12px' }} target='_blank' rel="noopener noreferrer">
                                        <img style={{ width: '160px' }} src={`${staticAssetsPrefix}/static/images/mac-badge.svg`} />
                                    </a> */}
                                        </div>
                                        {/* {showRating ? <div className='my-star'>
                                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                                            &nbsp;
                                            <h4 style={{ marginBottom: 0 }}>4.8 (3.9k+)</h4>
                                        </div>
                                            : <div className='my-star-empty'></div>
                                        } */}
                                    </Col>
                                    <Col xs={24} md={13}>
                                        <div style={{ position: 'relative' }}>
                                            {/* <div className='desktop-image' style={{ width: '80%', marginLeft: '40px' }}>
                                                <picture>
                                                    <source media="(max-width: 768px)" sizes="1px" srcSet={`data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA71w`} />
                                                    <img style={{ maxWidth: '90%', borderRadius: '16px' }} className='' src={`${staticAssetsPrefix}/static/images/main-page/LandingDesktop.png`} alt='Descktop app of Accelerated' />
                                                </picture>
                                            </div> */}
                                            <div className='mobile-image' style={{}}>
                                                <picture>
                                                    <source media="(max-width: 768px)" sizes="1px" srcSet={`${staticAssetsPrefix}/static/images/main-page/LandingLargePodurama2.png`} />
                                                    <img style={{ width: '100%' }} src={`${staticAssetsPrefix}/static/images/main-page/LandingLargePodurama2.png`} alt="Mobile app of Accelerated" />
                                                </picture>
                                            </div>
                                        </div>
                                        {/* <div style={{ position: 'relative', zIndex: '10' }} className='fadeInBottom iphone-image'>
                                            <picture>
                                                <source srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" media="(max-width: 760px)" />
                                                <source srcSet={`${staticAssetsPrefix}/static/images/main-page/Landing.png`} type="image/webp"></source>
                                                <img className='landing-svg' src={`${staticAssetsPrefix}/static/images/main-page/Landing.png`} alt="Mobile app of Accelerated" />
                                            </picture>
                                        </div>
                                        <div style={{ position: 'relative', zIndex: '10' }} className='fadeInBottom iphone-image'>
                                            <picture>
                                                <source srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" media="(max-width: 760px)" />
                                                <source srcSet={`${staticAssetsPrefix}/static/images/main-page/LandingDesktop.png`} type="image/webp"></source>
                                                <img className='landing-svg' src={`${staticAssetsPrefix}/static/images/main-page/LandingDesktop.png`} alt="Mobile app of Accelerated" />
                                            </picture>
                                        </div> */}
                                    </Col>
                                </Row>
                            </div>
                            {/* <div className='featured-in'>Featured in</div> */}
                            <div style={{ height: '100px' }}></div>
                            <hr style={{ opacity: '0.25' }} />
                            <div className='brands' style={{}}>
                                <img style={{ width: '120px', transform: 'translate(0px, 0px)', opacity: '0.7' }}
                                    src={`${staticAssetsPrefix}/static/images/Brands/Wired_logo.svg.png`} />
                                <img style={{ width: '130px', opacity: '1', transform: 'translate(0px, -2px)' }} src={`${staticAssetsPrefix}/static/images/Brands/Mashable-small.png`} />
                                <img style={{ width: '140px', opacity: '0.8', transform: 'translate(0,1px)' }} src={`${staticAssetsPrefix}/static/images/Brands/Entrepreneur_logo.png`} />
                                {/* <img style={{ width: '96px' }} src={`${staticAssetsPrefix}/static/images/Brands/Lifehacker.svg.png`} /> */}
                                <img style={{ width: '140px', opacity: '0.8', transform: 'translate(0,-1px)' }} src={`${staticAssetsPrefix}/static/images/Brands/hn-logo.png`} />
                            </div>
                            <hr style={{ opacity: '0.25' }} />
                            {/* </Col> */}
                            {/* <Col xs={24} md={24} xl={15} style={{
                                    textAlign: 'center'
                                }}>
                                    <div style={{ position: 'relative', zIndex: '10' }} className='fadeInBottom iphone-image'>
                                        <picture>
                                            <source srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" media="(max-width: 760px)" />
                                            <source srcSet={`${staticAssetsPrefix}/static/images/home/1.webp`} type="image/webp"></source>
                                            <img className='landing-svg' src={`${staticAssetsPrefix}/static/images/home/1.png`} alt="Image of iphone app of Accelerated" />
                                        </picture>
                                    </div>
                                </Col> */}
                            {/* </Row> */}
                            {/* <div className='video-div' style={{ maxWidth: '960px', margin: '1.25em auto', position: 'relative' }}>
                                <video className='video-main' width="98%" poster={`${staticAssetsPrefix}/static/images/free-sync3.jpg`} controls preload="metadata">
                                    <source src="https://audio-us.b-cdn.net/Promo-video.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                {isVideoPaused && <div className='video-overlay'>
                                    <img onClick={this.play} style={{ maxWidth: '100%' }} src={`${staticAssetsPrefix}/static/images/play-button.png`} />
                                </div>}
                            </div> */}
                            {/* <div style={{ position: 'relative' }}>
                                <div className='desktop-image' style={{ width: '70%', marginLeft: '15%' }}>
                                    <picture>
                                        <source media="(max-width: 768px)" sizes="1px" srcSet={`data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA71w`} />
                                        <img style={{ maxWidth: '100%', borderRadius: '16px' }} className='' src={`${staticAssetsPrefix}/static/images/home/Home_page_2_fin.png`} alt='Descktop app of Accelerated' />
                                    </picture>
                                </div>
                                <div className='mobile-image' style={{ position: 'absolute', bottom: '0', right: '12%', width: '85%' }}>
                                    <img style={{ width: '100%' }} src={`${staticAssetsPrefix}/static/images/home/mobile-3-min-low.png`} alt="Mobile app of Accelerated" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <style jsx>
                    {`
                        .icon-bg {
                            display: inline;
                            margin-right: 8px;
                            background: orange;
                            padding: 4px 8px;
                            border-radius: 50%;
                            color: white;
                        }
                        .img-parent {
                            position: absolute;
                            width: 100vw;
                        }
                        .img-parent img {
                            width: 100%;
                            height: 120vh;
                        }
                        .container-sm {
                            padding: 0 4em;
                        }
                        .featured-in {
                            font-family: 'Quicksand', sans-serif;
                            font-weight: 500;
                        }
                        .brands img {
                            margin: 16px 32px;
                        }
                        .video-main {
                            border-radius: 12px;
                            box-shadow: 0 20px 40px 0 rgba(0,11,40,.14);
                        }
                        .video-overlay {
                            position: absolute;
                            left: calc(50% - 50px);
                            top: calc(50% - 50px);
                            width: 100px;
                        }
                        .my-star {
                            display: flex;
                            justify-content: center;
                            margin-top: 8px;
                            align-items: center;

                            width: 160px;
                            text-align: center;
                            margin: auto;
                        }
                        .my-star-empty {
                            margin-top: 8px;
                            height: 22px;
                        }
                        .new-bg {
                            min-height: 80vh;
                            // background-image: url(${staticAssetsPrefix}/static/images/bg-6.png);
                            // background-image: url(https://d19m59y37dris4.cloudfront.net/app/2-0/img/banner-4.86a86274.png);
                            // background: linear-gradient(to top, #fff 0%, #fff 50%, ${colors.landing} 40%, ${colors.landing} 50%);
                            // background: linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
                            // background-size: cover;
                            padding-top: 56px;
                            // padding-bottom: 100px;
                        }
                        .app-stores img {
                            box-shadow: 0 20px 40px 0 rgba(0,11,40,.14);
                        }
                    //     .new-bg::before {    
                    //         content: "";
                    //         // background-image: url('https://placekitten.com/1200/800');
                    //         background-image: url(https://static.vecteezy.com/system/resources/previews/002/072/343/large_2x/abstract-colorful-background-free-vector.jpg);
                    //         background-size: cover;
                    //         position: absolute;
                    //         top: 0px;
                    //         right: 0px;
                    //         bottom: 0px;
                    //         left: 0px;
                    //         opacity: 0.25;
                    //   }
                        .main-title{
                            margin: 0 ${spacing()};
                            margin-bottom: 10px;
                            // position: relative;
                            // top: 30%;
                            // transform: translateY(-40%);
                            // width: 70%;
                            // margin-left: 25%;
                            margin: auto;
                        }
                        .main-title-first {
                            // margin: auto;
                            }
                        .main-title h1 {
                            font-size: 48px; // 3em;
                            color: white;
                            // margin-top: 48px;
                            // margin-bottom: 8px;
                        }
                        .main-title p {
                            color: hsla(235.9,13.7%,100%,1); // #B6BEC9;
                            font-size: 1.1em;
                        }
                        :global(.main-title .button) {
                            // background: ${colors.third};
                            // border-color: ${colors.third};
                            // color: white;
                            width: 60%;
                            border-radius: 12px;
                            background: #e4001b !important; // rgb(94, 83, 171) !important;
                            border: none;
                            // height: ${spacing('xl')};
                            height: 40px;
                            font-size: 1.1em;
                        }
                        :global(.main-title .button h4) {
                            color: white;
                            margin-bottom: 0;
                        }
                        .iphone-image {
                            max-width: 500px;
                            margin: auto;
                        }
                        .iphone-image img {
                            max-width: 100%;
                        }
                        .hero {
                            // height: 120vh;
                            // margin-bottom: 24px;
                        }
                        .hero {
                            position: relative;
                            padding-top: 88px;
                            padding-bottom: 88px;
                            z-index: 0;
                       }
                        .hero .hero-bg {
                            position: absolute;
                            top: 0;
                            bottom: 0; // 42%;
                            left: 0;
                            right: 0;
                            background: #0B0D19; // color(bg, 1i);
                            z-index: -2;
                       }
                        .hero .hero-particles-container {
                            position: absolute;
                            top: 0;
                            bottom: 0; // 42%;
                            left: 0;
                            right: 0;
                       }
                        .hero::before, .hero::after {
                            content: '';
                            position: absolute;
                            left: 0; // calc(50% - 360px);
                            right: 0;
                            width: 100%; // 720px;
                            background-repeat: no-repeat;
                       }
                        .hero::before {
                            top: 0;
                            height: 320px; // 159px;
                            background-image: url('${staticAssetsPrefix}/static/images/home/hero-bg-top.svg');
                            background-size: cover; // 720px 159px;
                       }
                    //     .hero::after {
                    //         bottom: 0; // 42%;
                    //         height: 173px;
                    //         background-image: url('${staticAssetsPrefix}/static/images/home/hero-bg-bottom.svg');
                    //         background-size: cover; // 720px 173px;
                    //    }
                       .hero-inner {
                            position: relative;
                            z-index: 1;
                        }
                        
                        .hero-copy {
                            position: relative; /* To display elements above hero illustrations */
                            margin-bottom: 48px;
                        }
                        .mobile-image {
                            // max-width: 274px;
                            position: relative;
                        }
                        // .btn-width {
                        //     width: 300px;
                        // }
                        .desktop-image img {
                            margin-top: 100px;
                            margin-left: 100px;
                        }
                        @media ${minScreenSize.tablet} {
                            .mobile-image {
                                // max-width: 400px;
                                // position: absolute;
                                // top: 80px; 
                                // left: -80px; 
                                width: 100%;
                                margin: auto;
                            }
                            .title-align {
                                text-align: left;
                            }
                            .btn-width {
                                width: 420px;
                            }
                            .app-stores {
                                // padding-left: 44px;
                                text-align: left;
                            }
                        }
                        // @media ${maxScreenSize.mobileM} {
                        //     .video-div {
                        //         display: none;
                        //       }
                        // }
                        @media ${maxScreenSize.tablet} {
                            .container-sm {
                                padding: 0 2em;
                            }
                            :global(.main-title-first) {
                                padding: 0 2em;
                            }
                            .main-title{
                                // margin: ${spacing()};
                                position: relative;
                                top: 20%;
                                transform: translateY(0);
                                width: 100%;
                                margin-left: 0%;
                              }
                              .main-title h1{
                                font-size: 36px;
                                padding: 0 24px;
                                margin-top: 0;
                              }
                              .desktop-image {
                                  display: none;
                              }
                              .mobile-image {
                                //   opacity: 0.95;
                                //   top: -200px;
                                max-width: 100vw;
                              }
                                .mobile-image img {
                                    // width: 150% !important;
                                    // margin-left: -25%;   
                                }
                              .new-bg {
                                //   height: 100vh;
                                    padding-top: 100px;
                                }
                              .app-stores a {
                                display: block;
                                // text-align: left;
                                margin-bottom: 16px;
                                margin-right: 0 !important;
                                margin-left: 0 !important;
                              }
                              .app-stores img {
                                width: 160px !important;
                              }
                              .video-div {
                                transform: translateY(70px);
                              }
                        }
                        @media ${maxScreenSize.laptop} {
                            .new-bg {
                                //   height: 100vh;
                                    padding-top: 100px;
                                    min-height: 50vh;
                                }
                        }

                        @media ${minScreenSize.laptop} {
                            .main-title{
                                // top: 40%;
                                padding: 0 8%;
                                padding-top: 80px;
                                max-width: 1400px;
                            }
                            .mobile-image {
                                // max-width: 392px;
                                // opacity: 0.95;
                                // transform: rotate(12deg);
                            }
                            .desktop-image {
                                min-height: 500px;
                                opacity: 0.9;
                            }

                            .btn-width {
                                width: 488px;
                            }
                        }
                        @media ${minScreenSize.laptopL} {
                            .mobile-image {
                                max-width: 600px;
                            }
                        }
                    `}
                </style>
            </div>
        )
    }
}
